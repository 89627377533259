import React, { useState, useCallback, useRef, type FC } from 'react';
import { defineMessages, useIntl, FormattedMessage } from 'react-intl-next';
import { styled, css } from '@compiled/react';

import Textfield from '@atlaskit/textfield';
import EditorSearchIcon from '@atlaskit/icon/glyph/editor/search';
import { token } from '@atlaskit/tokens';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import SelectClearIcon from '@atlaskit/icon/glyph/select-clear';
import { N600 } from '@atlaskit/theme/colors';
import VisuallyHidden from '@atlaskit/visually-hidden';

import type { HoverPageCardProps } from '@confluence/page-card';
import { LoadableLazy, useSSRPlaceholderReplaceIdProp } from '@confluence/loadable';
import { usePageSpaceKey, usePageContentId } from '@confluence/page-context';
import { TOUCH, START_TOUCH } from '@confluence/navdex';
import { enrichExcerpt } from '@confluence/search-utils/entry-points/excerpt';
import { ErrorDisplay } from '@confluence/error-boundary';
import { useDebounce } from '@confluence/debounce';
import { useIsNav4Enabled } from '@confluence/nav4-enabled';

import { SpaceViewsList } from './SpaceViewsList/SpaceViewsList';
import { useContentTreeSearch } from './useContentTreeSearch';

const ContentTreeSearchEmptyLoader = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ContentTreeSearchEmpty" */ './ContentTreeSearchStates'
			)
		).ContentTreeSearchEmpty,
});

const ContentTreeSearchErrorLoader = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ContentTreeSearchError" */ './ContentTreeSearchStates'
			)
		).ContentTreeSearchError,
});

const i18n = defineMessages({
	searchLabel: {
		id: 'space-views.content-tree-search.placeholder',
		defaultMessage: 'Search by title',
		description: 'Placeholder text for content tree search input',
	},
	clearSearch: {
		id: 'space-views.content-tree-search.clear-search',
		defaultMessage: 'Clear search',
		description: 'Tooltip for clear search button',
	},
	searchInputDescription: {
		id: 'space-views.content-tree-search.input-description',
		defaultMessage: 'Results will update as you type.',
		description: 'Aria description for content tree search input',
	},
	searchResultsCount: {
		id: 'space-views.content-tree-search.results-count',
		defaultMessage: '{count} {count, plural, one {result} other {results}} found',
		description: 'Screen reader only text with search results count in content tree search',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TextfieldWrapperDiv = styled.div<{ isNav4Enabled: boolean }>({
	marginTop: token('space.100', '8px'),
	marginBottom: token('space.100', '8px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	marginLeft: ({ isNav4Enabled }) =>
		isNav4Enabled ? token('space.0', '0px') : token('space.100', '8px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > [data-ds--text-field--container]': {
		borderWidth: '1px',
		borderRadius: '4px',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	svg: {
		width: '20px',
		height: '20px',
		// this is to align the search icon with the other side nav icons
		marginLeft: token('space.025', '2px'),
		// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
		marginRight: '3px',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ClearSearchButton = styled.button({
	border: 'none',
	backgroundColor: 'transparent',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginRight: '1px',
	height: '26px',
	width: '26px',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	paddingRight: '9px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	cursor: 'pointer',
});

type ContentTreeSearchProps = React.PropsWithChildren<
	Partial<HoverPageCardProps> & {
		contentTreeSize?: number;
	}
>;

const overlayCSS = css({
	width: '100%',
	height: '100%',
});
const overlayLoading = css({
	opacity: 0.5,
	pointerEvents: 'none',
	cursor: 'not-allowed',
});
const DisabledOverlay: FC<{ loading: boolean }> = ({ loading, children }) => (
	<div css={[overlayCSS, loading && overlayLoading]} data-vc="content-tree-search-overlay">
		{children}
	</div>
);

export const ContentTreeSearch: FC<ContentTreeSearchProps> = ({
	children,
	contentTreeSize,
	...hoverPageCardProps
}: ContentTreeSearchProps) => {
	const ssrPlaceholderIdProp = useSSRPlaceholderReplaceIdProp();
	const intl = useIntl();
	const [spaceKey] = usePageSpaceKey();
	const [contentId] = usePageContentId();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const isNav4Enabled = useIsNav4Enabled();

	const inputRef = useRef<HTMLInputElement>(null);

	const [searchText, setSearchText] = useState('');
	const debouncedSearchText = useDebounce(searchText, 200);

	const { searchResult, loading, error } = useContentTreeSearch(debouncedSearchText);

	const handleChange = useCallback((e: any) => {
		const inputValue = e.currentTarget.value || '';
		setSearchText(inputValue);
	}, []);

	const handleOnClearSearch = useCallback(() => {
		setSearchText('');
	}, []);

	const onItemClick = useCallback(
		(id: string, index?: number) => {
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					action: 'clicked',
					actionSubject: 'pageTree',
					source: 'pageTree',
					objectId: id,
					attributes: {
						spaceView: 'contentTreeSearch',
						navdexPointType: TOUCH,
						searchResultCount: searchResult?.length,
						searchResultIndex: index,
						contentTreeSize,
					},
				},
			}).fire();
		},
		[createAnalyticsEvent, searchResult, contentTreeSize],
	);

	const handleTextfieldFocus = useCallback(() => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'focused',
				actionSubject: 'contentTreeSearch',
				source: 'sideNavigation',
				attributes: {
					navdexPointType: START_TOUCH,
					contentTreeSize,
				},
			},
		}).fire();
	}, [createAnalyticsEvent, contentTreeSize]);

	return (
		<div data-vc="content-tree-search" {...ssrPlaceholderIdProp}>
			<TextfieldWrapperDiv isNav4Enabled={isNav4Enabled} id="contentTreeSearch">
				<Textfield
					ref={inputRef}
					aria-label={intl.formatMessage(i18n.searchLabel)}
					aria-describedby="contentTreeSearchInfo"
					placeholder={intl.formatMessage(i18n.searchLabel)}
					onChange={handleChange}
					onFocus={handleTextfieldFocus}
					value={searchText}
					elemBeforeInput={
						<EditorSearchIcon label="" primaryColor={token('color.icon.accent.gray', '#626f86')} />
					}
					elemAfterInput={
						searchText ? (
							<ClearSearchButton onClick={handleOnClearSearch}>
								<SelectClearIcon
									label={intl.formatMessage(i18n.clearSearch)}
									size="small"
									primaryColor={token('color.icon.subtle', N600)}
								/>
							</ClearSearchButton>
						) : null
					}
					isCompact
				/>
				<VisuallyHidden id="contentTreeSearchInfo">
					{intl.formatMessage(i18n.searchInputDescription)}
				</VisuallyHidden>
			</TextfieldWrapperDiv>
			<DisabledOverlay loading={loading}>
				<div aria-live="assertive" aria-atomic="true">
					{error ? (
						<ErrorDisplay error={error}>
							<ContentTreeSearchErrorLoader />
						</ErrorDisplay>
					) : searchResult && searchResult.length === 0 ? (
						<ContentTreeSearchEmptyLoader />
					) : null}
				</div>
				{!error ? (
					!searchResult ? (
						children
					) : searchResult.length > 0 ? (
						<>
							<VisuallyHidden>
								<FormattedMessage
									{...i18n.searchResultsCount}
									values={{ count: searchResult.length }}
								/>
							</VisuallyHidden>
							<SpaceViewsList
								pagesList={searchResult}
								spaceKey={spaceKey}
								contentId={contentId}
								onItemClick={onItemClick}
								renderTitle={({ title }) => enrichExcerpt(title)}
								{...hoverPageCardProps}
							/>
						</>
					) : null
				) : null}
			</DisabledOverlay>
		</div>
	);
};
